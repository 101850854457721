<template>
  <div v-show="detail.name" :class="[isMobile ? 'mobile-aboutus-detail' : '', 'aboutus-detail', 'detail']">
    <div class="title">
      <div class="name">{{detail.name}}</div>
      <div class="info">发布时间：{{(detail.disploy_at && detail.disploy_at.substring(0,10)|| detail.date && detail.date.substring(0,10))}} | 来源：{{detail.source || '明亚官网'}}</div>
    </div>
    <div class="content" v-html="detail.detail || detail.content">
      
    </div>
    <div v-if="(type == 'news' || type == 'responsibility' || type == 'media') && (detail.next || detail.pre)" class="page">
      <div class="list">
        <div @click="getOtherDetail(detail.pre)" v-if="detail.pre" class="list-item">上一条：{{detail.pre.name}}</div>
        <div @click="getOtherDetail(detail.next)" v-if="detail.next" class="list-item">下一条：{{detail.next.name}}</div>
      </div>
      <div v-if="!isMobile" @click="backList" class="btn-back">返回列表</div>
    </div>
  </div>
</template>

<script>
import { getEventDetail, getNewsAndDutyDetail } from '@/api/aboutUs'
import { isMobile } from '@/helper/utils'
export default {
  data () {
    return {
      isMobile: isMobile(),
      id: '',
      detail: {},
      type: ''
    }
  },
  created () {
    this.id = this.$route.query.id
    this.type = this.$route.query.type
    if (this.type == 'news' || this.type == 'responsibility' || this.type == 'media') {
      this.getNewsOrDuty()
    } else if (this.type != '') {
      this.getHonor()
    }
    document.body.scrollTop=document.documentElement.scrollTop = 0 
  },
  filters: {
    dateFormat (val) {
      let d = new Date(val)
      let year = d.getFullYear()
      let month = d.getMonth() + 1
      month = month < 0 ? '0' + month : month
      let day = d.getDate()
      d = day < 0 ? '0' + day : day
      return day + '/' + month + ' .' + year
    }
  },
 
  watch:{
    $route(to){
      this.id = this.$route.query.id
      this.type = this.$route.query.type
      if (to.name != 'Detail') {
        this.$emit('loading', false)
        return false
      } else {
        if (this.type == 'news' || this.type == 'responsibility' || this.type == 'media') {
          this.getNewsOrDuty()
          
        } else {
          this.getHonor()
          
        }
        // document.body.scrollTop=document.documentElement.scrollTop = 0
      }
    }
  },
  methods: {
    getDetail1 () {
      this.id = this.$route.query.id
      this.type = this.$route.query.type
      if (this.type == 'news' || this.type == 'responsibility') {
        this.getNewsOrDuty()
      } else if (this.type != '') {
        this.getHonor()
      }
      document.body.scrollTop=document.documentElement.scrollTop = 0 
    },
    // 返回上级列表
    backList () {
      if (this.type == 'news') {
        this.$router.push({ path: '/aboutUs/news' })
      } else if (this.type == 'media') {
        this.$router.push({ path: '/aboutUs/media' })
      } else if (this.type == 'responsibility') {
        this.$router.push({ path: '/aboutUs/responsibility' })
      }
    },
    getOtherDetail (data) {
      this.id = data.id
      let path = this.$router.history.current.path
      this.$router.push({path, query: {type: this.type, id: data.id}})
      this.$emit('loading', true)
      document.body.scrollTop=document.documentElement.scrollTop=0
      this.getNewsOrDuty()
    },
    // 获取荣誉详情
    async getHonor () {
      const res = await getEventDetail({ id: this.id })
      res.content = this.escape2Html(res.content)
      this.detail = res
      this.$emit('loading', false)
    },
    // 获取新闻或社会责任详情
    async getNewsOrDuty () {
      const res = await getNewsAndDutyDetail({ id: this.id })
      res.detail = this.escape2Html(res.detail)
      // console.log(res.detail)
      this.detail = res
      this.$emit('loading', false)
    },
    html2Escape (str) {
      return str.replace(/[<>&"]/g, function (c) {
        return { '<': '&lt;', '>': '&gt;', '&': '&amp;', '"': '&quot;' }[c]
      })
    },
    escape2Html (str) {
      var arrEntities = { 'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"' };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) {
        return arrEntities[t]
      })
    }
  }
}
</script>
<style lang="scss">
.mobile-aboutus-detail.aboutus-detail {
  margin-left: 0!important;
  // padding-top: 40px;
  .content {
    p, a, span {
      font-size: 12px!important;
      line-height: 28px!important;
      font-family: "Microsoft YaHei", Arial, sans-serif!important;
    }    
  }
  .page {
    padding-top: 20px!important;
    padding-bottom: 0!important;
  }
}
.aboutus-detail {
  .content {
    img {
      margin: 0 auto;
      // transform: translateX(-2em);
      max-width: 100%!important;
      height: auto!important;
      display: block;
    }
    p, section {
      margin-top: .3rem!important;
    }
    p, section, span {
      // text-indent: 2em;
      line-height: 2em!important;
      font-size: 16px!important;
      font-family: "Microsoft YaHei", Arial, sans-serif!important;
      img {
        margin: 0 auto!important;
        max-width: 100%!important;
        height: auto!important;
      }
      margin-bottom: 0;
      font-size: 16px;
      text-align: justify;
      span {
        text-align: justify;
        font-size: 16px;
        // line-height: normal;
      }
    }
  }
}
</style>
<style lang="scss" scoped>

.detail {
  margin-left: 0.6rem;
  .title {
    // height: 1.65rem;
    padding-bottom: .4rem;
    padding-top: .2rem;
    border-bottom: 1px solid #DCDCDC;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .name {
      font-size: .36rem;
      font-weight: bold;
    }
    .info {
      font-size: .16rem;
      color: #999999;
      margin-top: .2rem;
    }
  }
  .content {
    // border-bottom: 1px solid #DCDCDC;
    // padding-top: .4rem;
  }
  .page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .4rem 0 0;
    border-top: 1px solid #DCDCDC;
    margin-top: .6rem;
    .list {
      padding-right: .3rem;
      flex: 1;
      .list-item {
        display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 1; overflow: hidden;
        font-size: .16rem;
        color: #666666;
        cursor: pointer;
        line-height: .3rem;
        &:hover {
          color: #EE5400;
        }
      }
    }
    .btn-back {
      width: 1.4rem;
      height: .44rem;
      background: #FFFFFF;
      border: 1px solid #D0D0D0;
      border-radius: .22rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: .16rem;
      color: #666666;
      transition: all .3s;
      cursor: pointer;
      &:hover {
        background-color: #EE5400;
        border-color: #EE5400;
        color: #ffffff;
      }
    }
  }
}
</style>